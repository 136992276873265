import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { interval, take, timeInterval } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardUserData } from '../up-board-editor/up-board-editor.component';

@Component({
  selector: 'app-admin-token',
  templateUrl: './admin-token.component.html',
  styleUrls: ['./admin-token.component.css']
})
export class AdminTokenComponent implements OnInit {
  boardId: string | null = null;
  boardUser: BoardUserData | null = null;
  error: string | null = null;
  successMessage: string | null = null;

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute
  ) { }

  async save(token: string, confirmToken: string) {
    if (!this.boardId) return;
    if (!this.boardUser) return;

    if (!token || !confirmToken) {
      this.error = 'Admin Token cannot be empty';
      return;
    }

    if (token != confirmToken) {
      this.error = 'Tokens do not match';
      return;
    }

    this.error = null;

    this.boardUser.authToken = token;

    await this.db.collection('boards')
           .doc(this.boardId)
           .collection('users')
           .doc<BoardUserData>(this.authService.userData?.email)
           .set(this.boardUser);

    this.successMessage = 'Token updated successfully!';
    interval(5000).pipe(take(1)).subscribe(v => {
      this.successMessage = null;
    })
  }

  userHasAdminPermissions(user: BoardUserData | null) {
    return user?.roles.admin;
  }

  ngOnInit(): void {
    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;

      this.boardId = this.route.snapshot.paramMap.get('id');

      if (!this.boardId) return;

      this.db.collection('boards')
        .doc(this.boardId)
        .collection('users')
        .doc<any>(u.email)
        .valueChanges()
        .subscribe(user => {
          this.boardUser = user;
        });
    });

  }
}
