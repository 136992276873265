import { Component, OnInit } from '@angular/core';
import * as stripe from '@stripe/firestore-stripe-payments'
import * as firebase from '@firebase/app'
import { environment } from 'src/environments/environment';
import { formatCurrency } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/shared/services/auth';
import { httpsCallable, getFunctions } from '@angular/fire/functions'


@Component({
  selector: 'app-my-account-subscription',
  templateUrl: './my-account-subscription.component.html',
  styleUrls: ['./my-account-subscription.component.css']
})
export class MyAccountSubscriptionComponent implements OnInit {

  products: stripe.Product[] = [];
  formatCurrency = formatCurrency;
  payments: stripe.StripePayments | undefined;
  subscriptions: stripe.Subscription[] = [];
  currentProduct: stripe.Product | undefined;
  currentPrice: stripe.Price | undefined;
  currentUserPayments: stripe.Payment[] | undefined;

  constructor(
    public auth: AngularFireAuth,
    public authService: AuthService,
    public db: AngularFirestore
  )
  { }

  async gotoCustomerPortal() {

    const functionRef = httpsCallable(getFunctions(firebase.getApp()), 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.href });
    const res: any = data;

    window.location.assign(res.url);
  }

  async purchase(price: stripe.Price) {
    if (!this.payments) return;

    var sess = await stripe.createCheckoutSession(this.payments, {
      line_items: [
        {
          price: price.id,
          quantity: 1
        }
      ]
    });

    window.location.assign(sess.url);
  }

  async ngOnInit() {

    /*
    const doc = await this.db.collection("board_requests")
      .add({
        name: "My Favorite Board"
      });
    
    this.db.collection("board_requests")
           .doc(doc.id)
           .valueChanges()
           .subscribe(d => {
             this.not_exists = d
           });
    */


    var app = firebase.initializeApp(environment.firebase);
    this.payments = stripe.getStripePayments(app, {
      productsCollection: 'products',
      customersCollection: 'customers',
    });
    this.products = await stripe.getProducts(this.payments, {
      includePrices: true,
      activeOnly: true
    });

    this.subscriptions = await stripe.getCurrentUserSubscriptions(this.payments, {
      status: 'active'
    });

    const sub = this.subscriptions[0];

    this.currentProduct =  await stripe.getProduct(this.payments, sub.product, {
      includePrices: false
    });

    this.currentPrice = await stripe.getPrice(this.payments, sub.product, sub.price);

    stripe.getCurrentUserPayments(this.payments).then(payments => {
      alert(payments);
    });

    this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        this.db.collection('customers')
               .doc(user.uid)
               .collection<stripe.Payment>('payments')
               .valueChanges()
               .subscribe(payments => {
                 this.currentUserPayments = payments;
               });
      }
    });
  }

}
