import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute } from '@angular/router';
import * as icons from '@fortawesome/free-solid-svg-icons';
import * as uuid from 'uuid';
declare var $: any;

@Component({
  selector: 'app-up-board-admin-logo',
  templateUrl: './up-board-admin-logo.component.html',
  styleUrls: ['./up-board-admin-logo.component.css']
})
export class UpBoardAdminLogoComponent implements OnInit {

  icons = icons;
  public boardId: string | undefined;
  public uploadStatus: string = '';
  public logos: {url: string, path: string, thumbnailPath: string}[] = [];
  public selectedLogo: { url: string, path: string } | undefined;

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage
  ) { }

  selectLogo(logo: { url: string, path: string } | undefined) {
    if (!this.boardId) return;
    this.selectedLogo = logo;

    this.db.collection('boards')
            .doc(this.boardId)
            .set({
              data: {
                logoUrl: this.selectedLogo
                  ? this.selectedLogo.url
                  : ''
              }
            }, { merge: true});
  }

  public selectedFile: any = null;
  public uploadPercent: string = '0%';

  async selectFile(event: any) {
    this.selectedFile = event.target.files[0];
  }

  async uploadFile() {
    if (!this.selectedFile) return;
    const fileName = `${uuid.v4()}.jpg`;
    const filePath = `${this.boardId}/logos/${fileName}`;
    const thumbnailPath = `${this.boardId}/logos/thumb_${fileName}`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(this.selectedFile);
    task.percentageChanges().subscribe(p => {
      this.uploadPercent = Math.floor(p || 0) + '%';
    });
    this.uploadStatus = 'uploading';
    task.then(async (s) => {
      this.uploadStatus = 'finished';
      var url = await s.ref.getDownloadURL();

      if (this.boardId) {
        this.logos.push({url, path: filePath, thumbnailPath});
        this.db.collection('boards')
                .doc(this.boardId)
                .set({
                  data: {
                    logos: this.logos
                  }
                }, { merge: true});
        $('#filePicker').val('');
        this.selectedFile = null;
      }

      setTimeout(() => {
        this.uploadStatus = '';
        this.uploadPercent = '0%';
      }, 2000);
    }).catch(e => {
      alert(e);
    });
  }

  deleteLogo(logo: {path: string, thumbnailPath: string | undefined}) {
    if (!logo) return;
    for (let i=0; i < this.logos.length; i++) {
      if (this.logos[i].path === logo.path) {
        this.logos.splice(i, 1);
        break;
      }
    }
    const ref = this.storage.ref(logo.path);
    ref.delete();

    if (logo.thumbnailPath) {
      const thumbRef = this.storage.ref(logo.thumbnailPath);
      thumbRef.delete();
    }
    this.db.collection('boards')
            .doc(this.boardId)
            .set({
              data: {
                logos: this.logos
              }
            }, { merge: true});
  }

  ngOnInit(): void {
    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;
      if (!this.route.parent) return;

      let boardId = this.route.parent.snapshot.paramMap.get('id');

      if (!boardId) return;
      this.boardId = boardId;

      this.db.collection('boards')
              .doc<any>(this.boardId)
              .valueChanges()
              .subscribe(b => {
                if (b.data) {
                  this.logos = b.data.logos || [];
                  this.logos.forEach(l => {
                    if (l.url == b.data.logoUrl) {
                      this.selectedLogo = l;
                    }
                  });
                }
              });
    });
  }
}
