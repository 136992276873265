import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UpBoardAdminUsersComponent } from '../up-board-admin-users/up-board-admin-users.component';
import { UpBoardAdminStyleComponent } from '../up-board-admin-style/up-board-admin-style.component';
import { UpBoardAdminLogoComponent } from '../up-board-admin-logo/up-board-admin-logo.component';

@Component({
  selector: 'app-up-board-admin',
  templateUrl: './up-board-admin.component.html',
  styleUrls: ['./up-board-admin.component.css']
})
export class UpBoardAdminComponent implements OnInit, OnDestroy {

  public url: string = '';
  public usersSelected: boolean = false;
  public styleSelected: boolean = false;
  public logoSelected: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location
  ) {
  }

  onActivate($event: any) {
    this.usersSelected = $event instanceof UpBoardAdminUsersComponent;
    this.styleSelected = $event instanceof UpBoardAdminStyleComponent;
    this.logoSelected = $event instanceof UpBoardAdminLogoComponent;
  }

  onAttach($event: any) {
    alert(typeof $event);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }
}

