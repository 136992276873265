<div class="container">
    <div class="row justify-content-center">
        <h1>Plans</h1>
    </div>
    <div class="row justify-content-center" *ngFor="let product of products">
        <div class="card mt-2 mr-2" style="width: 18rem;" *ngFor="let price of product.prices">
            <div class="card-body">
                <h5 class="card-title">{{ product.name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ formatCurrency((price.unit_amount || 0) / 100, 'en-us',
                    '$') }}
                    per {{ price.interval }}</h6>
                <p class="card-text">{{ product.description }}</p>
                <p class="card-text">{{ price.description }}</p>
                <p class="card-text" *ngIf="currentProduct?.id == product.id && currentPrice?.id == price.id">Subscribed!</p>
                <p class="card-text text-danger" *ngIf="!authService.userData">You must be logged in to purchase</p>
                <div *ngIf="!currentProduct?.id || !currentPrice?.id">
                    <button class="btn btn-primary btn-disabled" (click)="purchase(price)"
                        [disabled]="!authService.isLoggedIn">Purchase</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center m-2" *ngIf="currentProduct?.id && currentPrice?.id">
        <button class="btn btn-primary" (click)="gotoCustomerPortal()" >Manage Subscription</button>
    </div>
</div>
