<div class="modal fade" id="modal_delete_user" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalKeepSpotLabel">This action needs authorization</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>You are trying to delete {{ modal_user?.name }} ({{ modal_user?.email }})</p>
                <p>Are you sure you want to do this?</p>
                <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                    {{ modal_error }}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="modal_error=undefined;">No</button>
                <button type="button" class="btn btn-primary" (click)="removeUser(modal_user); modal_user = undefined; ">Yes</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="boardPermissions.userHasEditPermissions(boardPermissions.boardUser)">
  <table class="table table-sm table-light table-striped">
    <thead>
      <th></th>
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Admin</th>
      <th>Board Manager</th>
      <th>Salesman</th>
    </thead>
    <tbody>
      <tr *ngFor="let user of sort(users)">
        <td>
          <div class="btn-group" *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
            <button type="button" class="btn btn-secondary mr-1" title="Remove User" (click)="modal_user = user"
                    data-toggle="modal" data-target="#modal_delete_user">
              <fa-icon [icon]="icons.faTrashCan"></fa-icon>
            </button>
            <button type="button" class="btn btn-secondary" title="Save User"
              (click)="upsertBoardUser( { email: user.email, data: user })">
              <fa-icon [icon]="icons.faSave"></fa-icon>
            </button>
          </div>
        </td>
        <td>
          <div *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
            <input type="text" class="form-control" [(ngModel)]="user.name">
          </div>
          <div *ngIf="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
            {{ user.name }}
          </div>
        <td>
          {{ user.email }}
        </td>
        <td *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
          <input type="tel" class="form-control" [(ngModel)]="user.phone">
        </td>
        <td *ngIf="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
          {{ user.phone }}
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [checked]="user.roles.admin"
              [disabled]="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)"
              (change)="user.roles.admin=!user.roles.admin">
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [checked]="user.roles.board_manager"
              [disabled]="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)"
              (change)="user.roles.board_manager=!user.roles.board_manager">
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [checked]="user.roles.salesman"
              [disabled]="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)"
              (change)="user.roles.salesman=!user.roles.salesman">
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="7" class="text-center" *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
          <span><b>Add a new User</b></span>
        </td>
        <td colspan="4" class="text-center" *ngIf="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
          <span><b>Add Salesman</b></span>
        </td>
      </tr>
      <tr *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
        <td>
          <button type="button" class="btn btn-secondary" title="Add User"
            [disabled]="!new_user.email || !new_user.data.name"
            (click)="upsertBoardUser(new_user);
              email.value='';
              name.value='';
              phone.value='';
              isAdmin.checked=false;
              isBoardManager.checked = false;
              isSalesman.checked = false;">
            <fa-icon [icon]="icons.faPlus"></fa-icon>
          </button>
        </td>
        <td>
          <input #name type="text" class="form-control" [(ngModel)]="new_user.data.name" placeholder="Name">
        <td>
          <input #email type="text" class="form-control" [(ngModel)]="new_user.email" placeholder="Email Address">
        </td>
        <td>
          <input #phone type="tel" class="form-control" [(ngModel)]="new_user.data.phone" placeholder="Phone Number">
        </td>
        <td>
          <div class="form-check">
            <input #isAdmin class="form-check-input" type="checkbox" [checked]="new_user.data.roles.admin"
              (change)="new_user.data.roles.admin=!new_user.data.roles.admin">
          </div>
        </td>
        <td>
          <div class="form-check">
            <input #isBoardManager class="form-check-input" type="checkbox"
              [checked]="new_user.data.roles.board_manager"
              (change)="new_user.data.roles.board_manager=!new_user.data.roles.board_manager">
          </div>
        </td>
        <td>
          <div class="form-check">
            <input #isSalesman class="form-check-input" type="checkbox" [checked]="new_user.data.roles.salesman"
              (change)="new_user.data.roles.salesman=!new_user.data.roles.salesman">
          </div>
        </td>
      </tr>
      <tr *ngIf="!boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
        <td>
          <button type="button" class="btn btn-secondary" title="Add Salesman"
            (click)="requestAddSalesman(new_user);
              email.value='';
              name.value='';
              phone.value='';"
              [disabled]="!new_user.email || !new_user.data.name"
              >
            <fa-icon [icon]="icons.faPlus"></fa-icon>
          </button>
        </td>
        <td>
          <input #name type="text" class="form-control" [(ngModel)]="new_user.data.name" placeholder="Name">
        <td>
          <input #email type="text" class="form-control" [(ngModel)]="new_user.email" placeholder="Email Address">
        </td>
        <td>
          <input #phone type="tel" class="form-control" [(ngModel)]="new_user.data.phone" placeholder="Phone Number">
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!boardPermissions.userHasEditPermissions(boardPermissions.boardUser)" class="text-center">
  <h1>403 Not Authorized</h1>
  <p>Whoops! You are not authorized to view this area.</p>
</div>
