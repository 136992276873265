<app-nav-bar></app-nav-bar>
<div class="viewer-container" id="view-container" (mousemove)="onMouseMove()">
    <div *ngIf="boardUser">
        <div *ngIf="board?.logoUrl" class="fixed-bottom" style="z-index: 0">
            <div class="float-right p-3 m-3 rounded shadow-sm" style="background-color: #ffffff55;">
                <img class="float-right" width="300px" [src]="board?.logoUrl" />
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <table class="table-up-board" style="width: 40%;">
                <thead>
                    <th>Available</th>
                    <th>Appointment</th>
                </thead>
                <tr *ngFor="let item of inStates(active, ['up','with_apt'])">
                    <td class="col-primary">
                        <div *ngIf="item.emp.state === 'up'">
                            <app-up-board-employee [emp]="item.emp" [index]="item.index" [up]="item.emp === up" [onDeck]="item.emp === onDeck"></app-up-board-employee>
                        </div>
                    </td>
                    <td class="col-primary">
                        <div *ngIf="item.emp.state === 'with_apt'">
                            <app-up-board-employee [emp]="item.emp" [index]="item.index"></app-up-board-employee>
                        </div>
                    </td>
                </tr>
            </table>
            <table class="table-up-board ml-2" style="width: 20%;">
                <thead>
                    <th style="width: 20%">With Guest</th>
                </thead>
                <tr *ngFor="let item of inState(active, 'with_guest')">
                    <td class="col-primary">
                        <app-up-board-employee [emp]="item.emp"></app-up-board-employee>
                    </td>
                </tr>
            </table>
            <div class="d-flex flex-column" style="width: 20%;">
                <table class="table-up-board ml-2">
                    <thead>
                        <th style="width: 20%">Finance/Delivery</th>
                    </thead>
                    <tr *ngFor="let item of inStates(active, ['delivery', 'with_guest_delivery', 'with_apt_delivery'])">
                        <td class="col-alternate">
                            <app-up-board-employee [emp]="item.emp" [index]="item.index"></app-up-board-employee>
                        </td>
                    </tr>
                </table>
                <table class="table-up-board mt-2 ml-2">
                    <thead>
                        <th style="width: 20%">Break</th>
                    </thead>
                    <tr *ngFor="let item of inState(active, 'break')">
                        <td class="col-alternate">
                            <app-up-board-employee [emp]="item.emp" [index]="item.index"></app-up-board-employee>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="d-flex flex-column" style="width: 20%">
                <table class="table-up-board ml-2">
                    <thead>
                        <th style="width: 20%" class="delivery">Deliveries (In Store)</th>
                    </thead>
                    <tr *ngFor="let delivery of inStore(deliveries, true)">
                        <td [ngClass]="{
                            'col-primary': true,
                            'delivery': true,
                            'bg-success': timeUtils.isOnSchedule(delivery, now),
                            'bg-warning': timeUtils.isWarning(delivery, now),
                            'bg-danger':  timeUtils.isAlert(delivery, now)
                        }">
                            <div class="border border-3">
                                <div>[{{ delivery.stock_number }}] {{ delivery.vehicle?.year }} {{ delivery.vehicle?.model }} {{ delivery.vehicle?.color }}</div>
                                <div>{{ delivery.emp }} ({{ timeUtils.inStoreForStr(delivery.arrival_time, now) }})</div>
                            </div>
                        </td>
                    </tr>
                </table>
                <table class="table-up-board ml-2 mt-2">
                    <thead>
                        <th style="width: 20%" class="delivery">Deliveries Priority 2</th>
                    </thead>
                    <tr *ngFor="let delivery of inStore(deliveries, false)">
                        <td [ngClass]="{
                            'col-primary': true,
                            'delivery': true,
                            'bg-success': timeUtils.isOnSchedule(delivery, now),
                            'bg-warning': timeUtils.isWarning(delivery, now),
                            'bg-danger':  timeUtils.isAlert(delivery, now)
                        }">
                            <div class="border border-3">
                                <div class="w-100">[{{ delivery.stock_number }}] {{ delivery.vehicle?.year }} {{ delivery.vehicle?.model }} {{ delivery.vehicle?.color }}</div>
                                <div class="w-100">{{ delivery.emp }} (
                                    <span *ngIf="delivery.arrival_time > now">in </span>
                                    {{ timeUtils.arrivingInStr(delivery.arrival_time, now) }}
                                    <span *ngIf="delivery.arrival_time <= now">late</span>)
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="fixed-bottom">
            <button id="btnToggle" class="btn btn-large btn-dark float-right m-3 shadow-sm" style="opacity: 0%;" (click)="toggleFullscreen()">
                <fa-icon [icon]="icons.faExpand"></fa-icon>
            </button>
        </div>

    </div>
    <div class="text-dark" *ngIf="loaded && !boardUser">
        <h1>Sorry you do now have rights to view this board.</h1>
    </div>
</div>
