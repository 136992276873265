import { BoardUserData } from '../components/up-board-editor/up-board-editor.component';
import { Employee } from '../components/up-board/up-board.component';

export function getUsersNotInState(
  users: BoardUserData[],
  state: string
): Employee[] {
  return users
    .filter((u) => u.roles.salesman && u.state !== state)
    .map((u) => {
      return {
        email: u.email,
        name: u.name,
        phone: u.phone,
        photoUrl: u.photoUrl,
        state: u.state,
        order: u.order,
      } as Employee;
    })
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    });
}
export function getUsersInState(
  users: BoardUserData[],
  state: string
): Employee[] {
  return users
    .filter((u) => u.roles.salesman && u.state === state)
    .map((u) => {
      return {
        email: u.email,
        name: u.name,
        phone: u.phone,
        photoUrl: u.photoUrl,
        state: u.state,
        order: u.order,
      } as Employee;
    })
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    });
}
