import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular/lib/firebaseui-angular-library.helper';
import { AuthService } from 'src/app/shared/services/auth';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(
    public router: Router,
    public authService: AuthService
  ) { }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    this.router.navigate(['/account/boards']);
  }

  errorCallback(errorData: FirebaseUISignInFailure) {
    alert('oops!')
  }

  uiShownCallback() {
  }

  ngOnInit(): void {
    this.authService.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.router.navigate(['/account/boards']);
      }
    });
  }

}
