<div class="panel panel-default">
    <div class="panel-heading">Viewer Background</div>
    <div class="panel-body">
        <div *ngFor="let url of builtInBackgrounds" class="d-inline-flex flex-row">
            <div [ngClass]="{'img-selected': fullImg(url)==selectedBackground, 'm-1': true}">
              <div [ngStyle]="{
                  'background': 'url(' + thumbnailImg(url) + ') 0% 0% / cover',
                  'width': '200px',
                  'height': '113px'
                }"
                (click)="selectBackground(fullImg(url))" style="cursor: pointer;">
              </div>
            </div>
        </div>
        <div *ngFor="let bg of customBackgrounds" class="d-inline-flex flex-row">
            <div [ngClass]="{'img-selected': bg.url==selectedBackground, 'm-1': true}">
              <div [ngStyle]="{
                  'background': 'url(' + imgUrl(bg) + ') 0% 0% / cover',
                  'width': '200px',
                  'height': '113px'
                }"
                (click)="selectBackground(bg.url)" style="cursor: pointer;">
                <button class="btn btn-sm btn-dark float-right m-1" style="opacity: 80%;" (click)="deleteBackground(bg)">
                  <fa-icon [icon]="icons.faTrash"></fa-icon>
                </button>
              </div>
            </div>
        </div>
    </div>
</div>


<div class="panel panedl-default">
  <div class="panel-heading">Upload Custom Background</div>
  <div class="panel-body">
    <div class="d-inline-flex flex-row">
      <input type="file" id="filePicker" (change)="selectFile($event)">
      <button class="btn btn-sm btn-dark m-1" title="Upload File" (click)="uploadFile()" [disabled]="!selectedFile">
        <fa-icon [icon]="icons.faUpload"></fa-icon>
      </button>
      <div class="ml-3 mr-3" *ngIf="uploadStatus">
        {{ uploadStatus }}
        {{ uploadPercent }}
      </div>
      <div class="progress align-middle" style="width: 300px;" *ngIf="uploadStatus">
        <div class="progress-bar" role="progressbar" [ngStyle]="{'width': uploadPercent}" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</div>
