import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import {
  BoardUser,
  BoardUserData,
} from '../up-board-editor/up-board-editor.component';
import { Board, Employee } from '../up-board/up-board.component';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { BoardPermissionsService } from 'src/app/shared/services/board-permissions.service';
declare var $: any;

@Component({
  selector: 'app-up-board-admin-users',
  templateUrl: './up-board-admin-users.component.html',
  styleUrls: ['./up-board-admin-users.component.css'],
})
export class UpBoardAdminUsersComponent implements OnInit {
  icons = icons;
  invited: string[] = [];
  users: (BoardUserData & { email: string })[] = [];
  new_user: BoardUser = {
    email: '',
    data: {
      email: '',
      name: '',
      phone: '',
      photoUrl: '',
      state: '',
      order: 0,
      roles: {
        admin: false,
        board_manager: false,
        salesman: false,
      },
    },
  };
  board: Board | undefined;
  boardId: string = '';
  boardUser: BoardUserData | null = null;
  modal_error: string | undefined;
  modal_user?: BoardUserData

  constructor(
    public db2: AngularFirestore,
    public auth: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    public boardPermissions: BoardPermissionsService
  ) {}

  async removeUser(user: BoardUserData | undefined) {
    if (!user) return;

    await this.db2
      .collection('boards')
      .doc(this.boardId)
      .collection('users')
      .doc(user.email)
      .delete();

    $('#modal_delete_user').modal('hide');
  }

  formatPhoneNumber(str: string) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? `${match[1]}` : '1';
      return ['+', intlCode, match[2], match[3], match[4]].join('');
    }

    return undefined;
  }

  async requestAddSalesman(user: BoardUser) {
    user.data.roles.salesman = true;
    user.data.roles.admin = false;
    user.data.roles.board_manager = false;

    var phone = this.formatPhoneNumber(user.data.phone) || '';
    user.data.phone = phone;

    await this.db2
      .collection('boards')
      .doc(this.boardId)
      .collection('add_user_requests')
      .add(user);
  }

  async upsertBoardUser(user: BoardUser) {
    var phone = this.formatPhoneNumber(user.data.phone) || '';

    user.data.phone = phone;
    user.data.email = user.email;

    if (!user.data.roles.salesman) {
      user.data.state = 'inactive';
    }

    await this.db2
      .collection('boards')
      .doc(this.boardId)
      .collection('users')
      .doc<BoardUserData>(user.email)
      .set(user.data);
  }

  sort(users: (BoardUserData&{ email: string; })[]): (BoardUserData&{ email: string; })[] {
    return users.sort((a, b) => {
      // sort alphabetically on name
      const an = a.name || a.email || '';
      const bn = b.name || b.email || '';
      return an.localeCompare(bn);
    });
  }

  ngOnInit(): void {
    var sub = this.auth.user.subscribe((u) => {
      sub.unsubscribe();

      if (!u || !u.email) return;
      if (!this.route.parent) return;

      let boardId = this.route.parent.snapshot.paramMap.get('id');

      if (!boardId) return;
      this.boardId = boardId;
      this.boardPermissions.init(this.boardId, u.email);

      this.db2
        .collection('boards')
        .doc(this.boardId)
        .collection<BoardUserData>('users')
        .valueChanges({ idField: 'email' })
        .subscribe((usrs) => {
          this.users = usrs;
        });

      this.db2
        .collection('boards')
        .doc<any>(this.boardId)
        .valueChanges()
        .subscribe((b) => {
          this.board = b.data;
        });
    });
  }
}
