import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '../up-board/up-board.component';

@Component({
  selector: 'app-up-board-employee',
  templateUrl: './up-board-employee.component.html',
  styleUrls: ['./up-board-employee.component.css']
})
export class UpBoardEmployeeComponent implements OnInit {

  @Input() editMode: boolean;
  @Input() up: boolean;
  @Input() onDeck: boolean;
  @Input() emp: Employee | null;
  @Input() index: number | null;

  constructor()
  {
    this.editMode = false;
    this.up = false;
    this.onDeck = false;
    this.emp = null;
    this.index = null;
  }

  ngOnInit(): void {
  }

}
