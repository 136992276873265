import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/shared/services/auth';

@Component({
  selector: 'app-my-account-boards',
  templateUrl: './my-account-boards.component.html',
  styleUrls: ['./my-account-boards.component.css']
})

export class MyAccountBoardsComponent implements OnInit {
  user: any;
  boards: any[] = [];

  constructor(
    public db: AngularFirestore,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.db.collection('users')
           .doc(this.authService.userData.uid)
           .valueChanges()
           .subscribe(async u => {
              this.user = u;

              // Trigger a cloud function to sync user boards
              await this.db.collection('users')
                      .doc(this.authService.userData.uid)
                      .collection('sync_board_requests')
                      .add({data: 'unused'});
           });

    this.db.collection('users')
           .doc(this.authService.userData.uid)
           .collection('boards')
           .valueChanges()
           .subscribe(b => {
              this.boards = [];
              b.forEach(e => {
                this.boards.push(e)
              });
           });
  }

}
