<!-- Modal -->
<div class="modal fade" id="modal_keep_spot" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalKeepSpotLabel">This action needs authorization</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    {{ modal_emp?.name ?? modal_emp?.email }} was with a guest. You are attempting to give them back
                    their place in line. This action requires an admin password.
                </p>

                <form>
                    <div class="form-group">
                        <input #adminCode type="password" class="form-control" id="adminCode" placeholder="Admin Code">
                    </div>
                    <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                        {{ modal_error }}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="modal_error=undefined;">Cancel</button>
                <button type="button" class="btn btn-primary"
                    (click)="authorizeKeepPlaceInLine(modal_emp, adminCode.value); adminCode.value=''">Authorize</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modal_drag_reorder" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalKeepSpotLabel">This action needs authorization</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    You are attempting to manually change the board order.
                    This action requires an admin password.
                </p>

                <form>
                    <div class="form-group">
                        <input #adminCodeReorder type="password" class="form-control" id="adminCodeReorder"
                            placeholder="Admin Code">
                    </div>
                    <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                        {{ modal_error }}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="cancelReorder()">Cancel</button>
                <button type="button" class="btn btn-primary"
                    (click)="authorizeReorder(adminCodeReorder.value); adminCodeReorder.value=''">Authorize</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modal_remove_emp" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalKeepSpotLabel">You are removing an employee from the board for the day.
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    You are attempting to remove {{ nextInactiveEmployee?.name || nextInactiveEmployee?.email }} from
                    the board for the day.
                    Are you sure you want to do this?
                </p>
                <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                    {{ modal_error }}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="authorizeMakeInactive(nextInactiveEmployee)">Remove From Board</button>
            </div>
        </div>
    </div>
</div>
<h3 class="m-3 text-center">Manage Board</h3>
<table class="table table-striped table-bordered" cdkDropList [cdkDropListData]="active" (cdkDropListDropped)="drop($event)">
    <thead>
        <tr>
            <th style="width: 30px;" *ngIf="editMode">+/-</th>
            <th style="width: 20%;" class="text-center"><b>Available</b></th>
            <th style="width: 20%;" class="text-center"><b>With Guest</b></th>
            <th style="width: 20%;" class="text-center"><b>Appointment</b></th>
            <th style="width: 20%;" class="text-center"><b>Delivery</b></th>
            <th style="width: 20%;" class="text-center"><b>Break</b></th>
            <th style="width: 30px;"></th>
        </tr>
    </thead>
    <tr *ngFor="let emp of unique(active)" cdkDrag [cdkDragData]="emp" [cdkDragDisabled]="!editMode">
        <div *cdkDragPreview class="bg-info text-dark">
            <app-up-board-employee [editMode]="true" [up]="emp === up" [onDeck]="emp === onDeck"
                [emp]="emp"></app-up-board-employee>
        </div>
        <td *ngIf="editMode">
            <button type="button" class="btn btn-secondary" (click)="makeInactive(emp)" title="Remove From Rotation"
                data-toggle="modal" data-target="#modal_remove_emp">
                <fa-icon [icon]="icons.faMinus"></fa-icon>
            </button>
        </td>
        <td *ngIf="emp.state != 'inactive'" class="col-available">
            <div>
                <div *ngIf="emp.state == 'up'">
                    <app-up-board-employee [editMode]="true" [emp]="emp" [up]="emp === up"
                        [onDeck]="emp === onDeck"></app-up-board-employee>
                </div>
                <div class="btn-group" role="group" *ngIf="emp.state != 'up'">
                    <button *ngIf="editMode && (emp.state == 'with_guest' || emp.state == 'with_guest_delivery')"
                        (click)="enterQueue(emp, true)" type="button" class="btn btn-secondary" title="Send To Bottom">
                        <fa-icon [icon]="icons.faArrowDown"></fa-icon>
                    </button>
                    <button
                        *ngIf="editMode && emp.state != 'up' && (emp.state != 'with_guest' && emp.state != 'with_guest_delivery')"
                        type="button" (click)="enterQueue(emp, false)" class="btn btn-secondary"
                        title="Keep Place In Line">
                        <fa-icon [icon]="icons.faArrowLeft"></fa-icon>
                    </button>

                    <button *ngIf="editMode && (emp.state == 'with_guest' || emp.state == 'with_guest_delivery')"
                        type="button" (click)="modal_emp = emp" class="btn btn-danger" title="Keep Place In Line"
                        data-toggle="modal" data-target="#modal_keep_spot">
                        <fa-icon [icon]="icons.faArrowLeft"></fa-icon>
                    </button>
                </div>
            </div>
        </td>
        <td class="col-with-guest">
            <div>
                <div *ngIf="emp.state == 'with_guest'" class="">
                    <app-up-board-employee [editMode]="true" [emp]="emp"></app-up-board-employee>
                </div>
                <div *ngIf="editMode && emp.state == 'up'">
                    <button (click)="sendWithGuest(emp)" type="button" title="Send With Guest" [ngClass]="
                        {
                            'btn': true,
                            'btn-success': emp === up,
                            'btn-warning': emp === onDeck,
                            'btn-secondary': emp !== up && emp !== onDeck
                        }">
                        <fa-icon [icon]="icons.faUser"></fa-icon>
                    </button>
                </div>
            </div>
        </td>
        <td *ngIf="emp.state != 'inactive'" class="col-with-apt">
            <div>
                <div *ngIf="emp.state == 'with_apt'">
                    <app-up-board-employee [editMode]="true" [emp]="emp"></app-up-board-employee>
                </div>
                <button type="button" class="btn btn-secondary" *ngIf="editMode && emp.state == 'up'"
                    (click)="sendOnApt(emp)" title="Send On Appointment">
                    <fa-icon [icon]="icons.faCalendar"></fa-icon>
                </button>
            </div>
        </td>
        <td *ngIf="emp.state != 'inactive'" class="col-delivery">
            <div>
                <div *ngIf="emp.state == 'with_guest_delivery' || emp.state == 'with_apt_delivery'">
                    <app-up-board-employee [editMode]="true" [emp]="emp"></app-up-board-employee>
                </div>
                <button *ngIf="editMode && (emp.state == 'with_guest' || emp.state == 'with_apt')"
                    (click)="sendOnDelivery(emp)" type="button" class="btn btn-secondary" title="Send On Delivery">
                    <fa-icon [icon]="icons.faTruck"></fa-icon>
                </button>
            </div>
        </td>
        <td *ngIf="emp.state != 'inactive'" class="col-break">
            <div>
                <div *ngIf="emp.state == 'break'" class="">
                    <app-up-board-employee [editMode]="true" [emp]="emp"></app-up-board-employee>
                </div>
                <button *ngIf="editMode && emp.state == 'up'" (click)="goOnBreak(emp)" type="button"
                    class="btn btn-secondary" title="Go On Break">
                    <fa-icon [icon]="icons.faHamburger"></fa-icon>
                </button>
            </div>
        </td>
        <td *ngIf="editMode">
            <div cdkDragHandle style="cursor: pointer;">
                <fa-icon [icon]="icons.faBars"></fa-icon>
            </div>
        </td>
    </tr>
    <tr *ngFor="let emp of sortInactive(inactive)" style="background-color: #afafafa0;">

        <td *ngIf="editMode">
            <button type="button" class="btn btn-secondary" *ngIf="editMode" (click)="makeActive(emp)"
                title="Add To Rotation">
                <fa-icon [icon]="icons.faPlus"></fa-icon>
            </button>
        </td>
        <td *ngIf="editMode" class="text-muted"> {{ emp.name }}</td>
        <td *ngIf="editMode"></td>
        <td *ngIf="editMode"></td>
        <td *ngIf="editMode"></td>
        <td *ngIf="editMode"></td>
    </tr>
</table>
