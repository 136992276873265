<div *ngIf="logos.length == 0">
    <p>No logos uploaded</p>
</div>
<div *ngIf="logos.length > 0" class="panel panel-default">
    <div class="panel-heading">Logos</div>
    <div class="panel-body">
        <div *ngFor="let logo of logos" class="d-inline-flex flex-row" style="cursor: pointer;">
            <div [ngClass]="{'img-selected': logo.url==selectedLogo?.url}">
                <img [src]="logo.url" width="200" class="p-2" (click)="selectLogo(logo)" />
                <button class="btn btn-sm btn-dark float-right m-1" style="opacity: 80%;" (click)="deleteLogo(logo)">
                  <fa-icon [icon]="icons.faTrash"></fa-icon>
                </button>
            </div>
        </div>
        <div class="d-inline-flex flex-row">
            <div [ngClass]="{'img-selected': !selectedLogo}" style="width: 200px; text-align: center;">
                <div (click)="selectLogo(undefined)" style="cursor: pointer;" >
                    <p class="text-danger" style="font-size: 100px" >X</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="panel panedl-default">
  <div class="panel-heading">Upload Custom Logo</div>
  <div class="panel-body">
    <div class="d-inline-flex flex-row">
      <input type="file" id="filePicker" (change)="selectFile($event)">
      <button class="btn btn-sm btn-dark m-1" title="Upload File" (click)="uploadFile()" [disabled]="!selectedFile">
        <fa-icon [icon]="icons.faUpload"></fa-icon>
      </button>
      <div class="ml-3 mr-3" *ngIf="uploadStatus">
        {{ uploadStatus }}
        {{ uploadPercent }}
      </div>
      <div class="progress align-middle" style="width: 300px;" *ngIf="uploadStatus">
        <div class="progress-bar" role="progressbar" [ngStyle]="{'width': uploadPercent}" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</div>
