<app-nav-bar></app-nav-bar>
<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-2">
            <h4>My Account</h4>
            <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[ 'subscription' ]">Subscription</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[ 'boards' ]">Boards</a>
                </li>
            </ul>
        </div>
        <div class="col-10">
            <router-outlet></router-outlet>
        </div>
    
    </div>

</div>