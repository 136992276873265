import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Firebase services + environment module
import {firebase, firebaseui, FirebaseUIModule} from 'firebaseui-angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth'
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { AuthService } from './shared/services/auth';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UpBoardComponent } from './components/up-board/up-board.component';
import { UpBoardViewerComponent } from './components/up-board-viewer/up-board-viewer.component';
import { UpBoardEditorComponent } from './components/up-board-editor/up-board-editor.component';
import { UpBoardEmployeeComponent } from './components/up-board-employee/up-board-employee.component';
import { UpBoardAdminComponent } from './components/up-board-admin/up-board-admin.component';
import { AdminTokenComponent } from './components/admin-token/admin-token.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { MyAccountBoardsComponent } from './components/my-account-boards/my-account-boards.component';
import { MyAccountSubscriptionComponent } from './components/my-account-subscription/my-account-subscription.component';
import { BoardPermissionsService } from './shared/services/board-permissions.service';
import { ManageDeliveriesComponent } from './components/manage-deliveries/manage-deliveries.component';
import { AuthComponent } from './components/auth/auth.component';
import { UpBoardAdminUsersComponent } from './components/up-board-admin-users/up-board-admin-users.component';
import { UpBoardAdminStyleComponent } from './components/up-board-admin-style/up-board-admin-style.component';
import { UpBoardAdminLogoComponent } from './components/up-board-admin-logo/up-board-admin-logo.component';
import { UpBoardManagerComponent } from './components/up-board-manager/up-board-manager.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
    },
    // {
    //   scopes: [
    //     'public_profile',
    //     'email',
    //     'user_likes',
    //     'user_friends'
    //   ],
    //   customParameters: {
    //     'auth_type': 'reauthenticate'
    //   },
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    // },
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // {
    //   requireDisplayName: false,
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    // },
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl: '<your-tos-link>',
  // privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [
    AppComponent,
    UpBoardComponent,
    UpBoardViewerComponent,
    UpBoardEditorComponent,
    UpBoardEmployeeComponent,
    UpBoardAdminComponent,
    AdminTokenComponent,
    NavBarComponent,
    LandingPageComponent,
    MyAccountComponent,
    MyAccountBoardsComponent,
    MyAccountSubscriptionComponent,
    ManageDeliveriesComponent,
    AuthComponent,
    UpBoardAdminUsersComponent,
    UpBoardAdminStyleComponent,
    UpBoardAdminLogoComponent,
    UpBoardManagerComponent,
    ContactInfoComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    FontAwesomeModule,
    DragDropModule
  ],
  providers: [
    AuthService,
    BoardPermissionsService,
    // { provide: USE_AUTH_EMULATOR, useValue: !environment.production ? ['localhost', 9099] : undefined },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUser);
  }
}
