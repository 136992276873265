import { Component, OnInit, Input } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';
import { AuthService } from 'src/app/shared/services/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BoardUserData } from '../up-board-editor/up-board-editor.component';
import {
  getUsersInState,
  getUsersNotInState,
} from 'src/app/utils/board-user-utils';
declare var $: any;

@Component({
  selector: 'app-up-board',
  templateUrl: './up-board.component.html',
  styleUrls: ['./up-board.component.css'],
})
export class UpBoardComponent implements OnInit {
  boardId: string | null;
  @Input() editMode: boolean;

  icons = icons;
  // board: Board | null | undefined;
  active: Employee[] | undefined;
  inactive: Employee[] | undefined;
  up: Employee | undefined;
  onDeck: Employee | undefined;
  modal_emp: Employee | undefined;
  modal_error: string | undefined;
  drag_event: CdkDragDrop<Employee[] | undefined> | undefined;
  old_active: Employee[] | null = null;
  nextInactiveEmployee: Employee | undefined;
  users: BoardUserData[] = [];

  constructor(
    public db: AngularFirestore,
    public authTokenSvc: AuthTokenService,
    public authService: AuthService,
    public auth: AngularFireAuth,
    private route: ActivatedRoute
  ) {
    this.boardId = null;
    this.editMode = false;
  }

  drop(event: CdkDragDrop<Employee[] | undefined>) {
    if (!this.active) return;
    this.drag_event = event;
    this.old_active = [];

    for (var i = 0; i < this.active.length; i++) {
      copyArrayItem(this.active, this.old_active, i, i);
    }
    moveItemInArray(
      this.active,
      this.drag_event.previousIndex,
      this.drag_event.currentIndex
    );
    $('#modal_drag_reorder').modal('show');
  }

  async authorizeReorder(adminCode: string) {
    if (!this.drag_event) return;

    const isLegal = await this.authTokenSvc.isLegalAuthToken(
      this.boardId || '',
      adminCode
    );

    if (!isLegal) {
      this.modal_error = 'Invalid Admin Code!';
      return;
    }

    this.save();
    $('#modal_drag_reorder').modal('hide');
  }

  sortInactive(emps: Employee[] | undefined) {
    const inactive: Employee[] = [];
    const active = this.active ?? [];

    emps?.forEach((a) => {
      const index = active.findIndex((b) => {
        return b.email === a.email;
      });

      if (index === -1) {
        inactive.push(a);
      }
    });

    return this.unique(inactive).sort((a, b) => {
      var na = a.name || a.email || '';
      var nb = b.name || b.email || '';
      return na.localeCompare(nb);
    });
  }

  uniqueReal(emps: Employee[] | undefined, users: string[]) {
    const uniqueArray: Employee[] = [];

    emps?.forEach((a) => {
      const index = uniqueArray.findIndex((b) => {
        return b.email === a.email;
      });

      const uIndex = users.findIndex((b) => {
        return b === a.email;
      });

      const add = index === -1;
      const real = uIndex >= 0;
      if (add && real) {
        uniqueArray.push(a);
      }
    });

    return uniqueArray;
  }

  unique(emps: Employee[] | undefined) {
    const uniqueArray: Employee[] = [];

    emps?.forEach((a) => {
      const index = uniqueArray.findIndex((b) => {
        return b.email === a.email;
      });

      if (index === -1) {
        uniqueArray.push(a);
      }
    });

    return uniqueArray;
  }

  cancelReorder() {
    if (!this.active) return;
    if (!this.old_active) return;
    this.modal_error = undefined;

    this.active = [];
    for (var i = 0; i < this.old_active.length; i++) {
      copyArrayItem(this.old_active, this.active, i, i);
    }
  }

  sendOnDelivery(emp: Employee) {
    if (emp.state == 'with_guest') {
      emp.state = 'with_guest_delivery';
    } else if ((emp.state = 'with_apt')) {
      emp.state = 'with_apt_delivery';
    } else {
      return;
    }

    this.save();
  }

  goOnBreak(emp: Employee) {
    emp.state = 'break';
    this.save();
  }

  sendToBack(emp: Employee) {
    if (!this.active) {
      this.active = [];
    }

    for (let i = 0; i < this.active.length; i++) {
      if (this.active[i] === emp) {
        this.active.splice(i, 1);
        break;
      }
    }
    this.active.push(emp);
  }

  async authorizeKeepPlaceInLine(emp: Employee | undefined, adminCode: string) {
    if (!emp) return;
    const isLegal = await this.authTokenSvc.isLegalAuthToken(
      this.boardId || '',
      adminCode
    );

    if (!isLegal) {
      this.modal_error = 'Invalid Admin Code!';
      return;
    }

    emp.state = 'up';
    this.modal_error = undefined;
    this.save();
    $('#modal_keep_spot').modal('hide');
  }

  async enterQueue(emp: Employee, sendToBack: boolean) {
    if (sendToBack) {
      this.sendToBack(emp);
    }

    emp.state = 'up';
    this.save();
  }

  makeInactive(emp: Employee) {
    this.nextInactiveEmployee = emp;
  }

  authorizeMakeInactive(emp: Employee | undefined) {
    if (!emp) return;

    if (!this.active) {
      this.active = [];
    }
    if (!this.inactive) {
      this.inactive = [];
    }

    for (let i = 0; i < this.active.length; i++) {
      if (this.active[i] === emp) {
        this.active.splice(i, 1);
        break;
      }
    }
    this.inactive.push(emp);
    emp.state = 'inactive';
    this.save();
  }

  makeActive(emp: Employee) {
    if (!this.inactive) return;

    for (let i = 0; i < this.inactive.length; i++) {
      if (this.inactive[i] === emp) {
        this.inactive.splice(i, 1);
        break;
      }
    }
    this.enterQueue(emp, true);
  }

  sendOnApt(emp: Employee) {
    emp.state = 'with_apt';
    this.save();
  }

  sendWithGuest(emp: Employee) {
    emp.state = 'with_guest';
    this.save();
  }

  save() {
    if (!this.boardId) {
      return;
    }

    if (!this.active) this.active = [];
    if (!this.inactive) this.inactive = [];
    this.setUpAndOnDeck(this.active);
    //this.db.object<Board>(`/boards/${this.boardId}/data`)
    //  .set(this.board);

    const batch = this.db.firestore.batch();

    for (let i = 0; i < this.active.length; i++) {
      const emp = this.active[i];
      const ref = this.db.firestore
        .collection('boards')
        .doc(this.boardId)
        .collection('users')
        .doc(emp.email);
      batch.update(ref, { state: emp.state, order: i });
    }

    for (let i = 0; i < this.inactive.length; i++) {
      const emp = this.inactive[i];
      const ref = this.db.firestore
        .collection('boards')
        .doc(this.boardId)
        .collection('users')
        .doc(emp.email);
      batch.update(ref, { state: emp.state, order: i });
    }

    batch.commit();

    this.db.collection('boards')
      .doc(this.boardId)
      .set({
        up: this.up?.email ?? '',
        onDeck: this.onDeck?.email ?? '',
      }, { merge: true });
  }

  ngOnInit(): void {
    var sub = this.auth.user.subscribe((u) => {
      sub.unsubscribe();

      if (!u || !u.email) return;
      this.boardId = this.route.snapshot.paramMap.get('id');
      if (!this.boardId) return;

      this.db
        .collection('boards')
        .doc(this.boardId)
        .collection<BoardUserData>('users')
        .valueChanges({ idField: 'email' })
        .subscribe((usrs) => {
          this.users = usrs.map(u => {
            u.state = u.state || "inactive";
            return u;
          });
          this.updateBoardState();
        });
    });
  }
  updateBoardState() {
    if (this.users?.length > 0) {
      this.active = getUsersNotInState(this.users, 'inactive');
      this.inactive = getUsersInState(this.users, 'inactive');
      this.setUpAndOnDeck(this.active);
    }
  }
  setUpAndOnDeck(active: Employee[]) {
    let available = [];
    for (let i = 0; i < active.length; i++) {
      let emp = active[i];
      if (emp.state == 'up') {
        available.push(emp);
      }
    }

    this.up = available[0];
    this.onDeck = available[1];
  }
}

export class Board {
  public viewerBackground: string | undefined;
  public logoUrl: string | undefined;
  public members: string[] | undefined;
  public deliveries: BoardDelivery[] | undefined;
}

export class Employee {
  public name: string | undefined;
  public phone: string | undefined;
  public state: string | undefined;
  public photoUrl: string | undefined;
  public email: string | undefined;
  public order: number = 0;
}

export interface Delivery {
  id: string;
  emp: string;
  vehicle: Vehicle;
  customer_in_store: boolean;
  stock_number: string;
  arrival_time: moment.Moment;
}

export interface BoardDelivery {
  id: string;
  emp: string;
  vehicle: Vehicle;
  customer_in_store: boolean;
  stock_number: string;
  arrival_time: string;
}

export interface Vehicle {
  color: string;
  model: string;
  year: number;
}
