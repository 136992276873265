<app-nav-bar></app-nav-bar>

<div *ngIf="userHasAdminPermissions(boardUser)" class="bg-dark text-white container">
    <form>
        <div class="form-group">
            <label for="token">Update Admin Token</label>
            <input #token type="password" class="form-control bg-light text-dark" id="token" placeholder="Admin Token">
        </div>
        <div class="form-group">
            <label for="token">Confirm Admin Token</label>
            <input #confirmToken type="password" class="form-control bg-light text-dark" id="token" placeholder="Admin Token">
        </div>
        <div *ngIf="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div *ngIf="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
        </div>
        <button class="btn btn-secondary" type="submit" (click)="save(token.value, confirmToken.value)">Save</button>
    </form>
</div>