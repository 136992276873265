import * as moment from 'moment';
import { Delivery } from '../components/up-board/up-board.component';

export class TimeUtils {

    constructor() {

    }

    isOnSchedule(delivery: Delivery, now: moment.Moment) {
        return (delivery.customer_in_store && !this.inStoreLongerThan(delivery.arrival_time, now, 5)) ||
               (!delivery.customer_in_store && !this.arrivingInLessThan(delivery.arrival_time, now, 75));
    }

    isWarning(delivery: Delivery, now: moment.Moment) {
        return (delivery.customer_in_store && this.inStoreLongerThan(delivery.arrival_time, now, 5) && !this.inStoreLongerThan(delivery.arrival_time, now, 10)) ||
               (!delivery.customer_in_store && !this.arrivingInLessThan(delivery.arrival_time, now, 45) && this.arrivingInLessThan(delivery.arrival_time, now, 75));
    }

    isAlert(delivery: Delivery, now: moment.Moment) {
        return (delivery.customer_in_store && this.inStoreLongerThan(delivery.arrival_time, now, 10)) ||
               (!delivery.customer_in_store && this.arrivingInLessThan(delivery.arrival_time, now, 45));
    }

    formatDuration(duration: moment.Duration) {
        var str = '';
        if (duration.hours() > 0) str += `${duration.hours()} hour`;
        if (duration.hours() > 1) str += "s";
        if (duration.minutes() > 0) str += ` ${duration.minutes()} min`;
        if (duration.hours() == 0 && duration.seconds() > 0) str += ` ${duration.seconds()} sec`;
        // return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
        return str;
    }

    arrivingInStr(time: moment.Moment | undefined, now: moment.Moment): string {

        if (!time) return '';
        var duration = moment.duration(time.diff(now)).abs();

        if (time > now && !this.arrivingInLessThan(time, now, 2 * 60)) {
            return duration.humanize();
        }

        if (time < now) {
            return duration.humanize();
        }

        return this.formatDuration(duration);
    }

    inStoreForStr(time: moment.Moment | undefined, now: moment.Moment): string {
        if (!time) return '';
        var duration = moment.duration(time.diff(now)).abs();
        return this.formatDuration(duration);
    }

    diffAsMin(time: moment.Moment | undefined, now: moment.Moment): any {
        if (!time) return 0;
        return moment.duration(time.diff(now)).asMinutes();
    }

    arrivingInLessThan(time: moment.Moment | undefined, now: moment.Moment, threshold: number): boolean {
        if (!time) return false;
        return moment.duration(time.diff(now)).asMinutes() < threshold
    }

    arrivingInMin(time: moment.Moment | undefined, now: moment.Moment): number {
        if (!time) return 0;
        return moment.duration(time.diff(now)).asMinutes()
    }

    inStoreLongerThan(time: moment.Moment | undefined, now: moment.Moment, threshold: number): boolean {
        if (!time) return false;
        return moment.duration(now.diff(time)).asMinutes() > threshold
    }
}