<div class="modal fade" id="modal_add_delivery" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delivery Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" #in_store type="checkbox" id="inStore">
                        <label class="form-check-label" for="inStore">Customer In Store?</label>
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="year">Year</label>
                        <input type="number" class="form-control" #year id="year">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="model">Model</label>
                        <input type="text" class="form-control" #model id="model">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="color">Color</label>
                        <input type="text" class="form-control" #color id="color">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="color">Stock Number</label>
                        <input type="text" class="form-control" #stock_number id="stock_number">
                    </div>

                    <select class="form-select form-select-lg mb-3" #salesman>
                        <option selected>Salesman</option>
                        <option *ngFor="let emp of salesmen" [value]="emp.name">{{ emp.name }}</option>
                    </select>

                    <div class="form-group">
                        <label for="arrival_time"
                            [ngClass]="{ 'form-check-label': true, 'invisible': in_store.checked }">Arrival Time</label>
                        <input type="datetime-local" #time id="arrival_time" step="900"
                            [ngClass]="{ 'form-control': true, 'invisible': in_store.checked }">
                    </div>
                    <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                        {{ modal_error }}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="modal_error=undefined;">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="addDelivery({
                    id: 'new',
                    customer_in_store: in_store.checked,
                    arrival_time: strToMoment(time.value),
                    stock_number: stock_number.value,
                    vehicle: {
                        color: color.value,
                        model: model.value,
                        year: strToInt(year.value)
                    },
                    emp: salesman.value
                });
                in_store.checked = false;
                time.value = '';
                stock_number.value = '';
                color.value = '';
                model.value = '';
                year.value = '';
                salesman.value = '';
                ">Add Delivery</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modal_edit_delivery" tabindex="-1" role="dialog" aria-labelledby="modalKeepSpotLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delivery Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" #edit_in_store type="checkbox" id="edit_inStore"
                            [checked]="curDelivery?.customer_in_store" (change)="setArrivingNow(edit_in_store.checked)">
                        <label class="form-check-label" for="edit_inStore">Customer In Store?</label>
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="year">Year</label>
                        <input type="number" class="form-control" #edit_year id="edit_year"
                            [value]="curDelivery?.vehicle?.year">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="model">Model</label>
                        <input type="text" class="form-control" #edit_model id="edit_model"
                            [value]="curDelivery?.vehicle?.model">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="color">Color</label>
                        <input type="text" class="form-control" #edit_color id="edit_color"
                            [value]="curDelivery?.vehicle?.color">
                    </div>
                    <div class="form-group">
                        <label class="form-check-label" for="color">Stock Number</label>
                        <input type="text" class="form-control" #edit_stock_number id="edit_stock_number"
                            [value]="curDelivery?.stock_number">
                    </div>

                    <select class="form-select form-select-lg mb-3" #edit_salesman>
                        <option *ngFor="let emp of salesmen" [value]="emp.name"
                            [selected]="curDelivery?.emp == emp.name">{{ emp.name }}</option>
                    </select>

                    <div class="form-group">
                        <label class="form-check-label" for="arrival_time">Arrival Time</label>
                        <input type="datetime-local" class="form-control" #edit_time id="edit_arrival_time" step="900">
                    </div>
                    <div *ngIf="modal_error" class="alert alert-danger" role="alert">
                        {{ modal_error }}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal_error=undefined;
                             curDelivery = undefined;">
                    Cancel</button>
                <button type="button" class="btn btn-primary"
                    (click)="saveDelivery(edit_in_store.checked, edit_stock_number.value, strToMoment(edit_time.value), edit_color.value, edit_model.value, strToInt(edit_year.value), edit_salesman.value)">Save</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="boardPermissions.userHasEditPermissions(boardPermissions.boardUser)">
    <h3 class="m-3 text-center">Manage Deliveries</h3>
    <table class="table">
        <tbody>
            <tr>
                <td>
                    <button type="button" class="btn btn-secondary" title="Add Delivery" data-toggle="modal"
                        data-target="#modal_add_delivery">
                        <fa-icon [icon]="icons.faPlus"></fa-icon>
                    </button>
                </td>
                <td></td>
                <th>Priority</th>
                <th>Year</th>
                <th>Model</th>
                <th>Color</th>
                <th>Stock Number</th>
                <th>Salesman</th>
                <th>Timer</th>
            </tr>
            <tr *ngFor="let delivery of sort(deliveries)" [ngClass]="{
                    'table-success': timeUtils.isOnSchedule(delivery, now),
                    'table-warning': timeUtils.isWarning(delivery, now),
                    'table-danger': timeUtils.isAlert(delivery, now)
                }">
                <td>
                    <button type="button" class="btn btn-secondary" title="Remove Delivery"
                        (click)="removeDelivery(delivery)">
                        <fa-icon [icon]="icons.faTrash"></fa-icon>
                    </button>
                </td>
                <td>
                    <button type="button" class="btn btn-secondary" title="Edit Delivery"
                        (click)="editDelivery(delivery)">
                        <fa-icon [icon]="icons.faPencil"></fa-icon>
                    </button>
                </td>
                <td>{{
                    delivery.customer_in_store
                    ? 'Here'
                    : delivery.arrival_time > now
                    ? 'Arriving in ' + timeUtils.arrivingInStr(delivery.arrival_time, now)
                    : timeUtils.arrivingInStr(delivery.arrival_time, now) + ' late'
                    }}

                </td>
                <td>{{ delivery.vehicle?.year }}</td>
                <td>{{ delivery.vehicle?.model }}</td>
                <td>{{ delivery.vehicle?.color }}</td>
                <td>{{ delivery.stock_number }}</td>
                <td>{{ delivery.emp }}</td>
                <td>{{
                    delivery.customer_in_store
                    ? timeUtils.inStoreForStr(delivery.arrival_time, now)
                    : ''
                    }}
                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <div class="float-right border shadow-sm m-2 p-2">
            <h4>Legend (In Store)</h4>
            <table class="table">
                <tr class="table-success">
                    <td>Customer in store less than 5 min</td>
                </tr>
                <tr class="table-warning">
                    <td>Customer in store between 5 - 10 min</td>
                </tr>
                <tr class="table-danger">
                    <td>Customer in store more than 10 min</td>
                </tr>
            </table>
            <h4>Legend (Scheduled to arrive)</h4>
            <table class="table">
                <tr class="table-success">
                    <td>Customer arriving in more than 1 hour 15 min</td>
                </tr>
                <tr class="table-warning">
                    <td>Customer arriving in less than 1 hour 15 min</td>
                </tr>
                <tr class="table-danger">
                    <td>Customer arriving in less than 45 min</td>
                </tr>
            </table>

        </div>

    </div>
</div>
<div *ngIf="!boardPermissions.userHasEditPermissions(boardPermissions.boardUser)" class="text-center">
    <h1>403 Not Authorized</h1>
    <p>Whoops! You are not authorized to view this area.</p>
</div>
