<app-nav-bar></app-nav-bar>
<div class="float-left m-5">
    <h4>Contact Information</h4>
    <table class="table mt-5">
    <tbody>
        <tr>
        <th scope="row">Address</th>
        <td>
            391 S 430 E. <br />
            Lehi UT, 84043
            </td>
        </tr>
        <tr>
        <th scope="row">Development</th>
        <td>(385) 201-9261</td>
        </tr>
        <tr>
        <th scope="row">Sales</th>
        <td>(801) 369-2561</td>
        </tr>
    </tbody>
    </table>
</div>