import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BoardUserData } from 'src/app/components/up-board-editor/up-board-editor.component';
import { AuthService } from './auth';

@Injectable({
  providedIn: 'root'
})
export class BoardPermissionsService {
  boardId: string | null = null;
  public boardUser: BoardUserData | undefined;

  constructor(
    private db: AngularFirestore,
    public authService: AuthService
  ) { }

  init(boardId: string, email: string) {
    this.db.collection('boards')
      .doc(boardId)
      .collection('users')
      .doc<BoardUserData>(email)
      .valueChanges()
      .subscribe(user => {
        this.boardUser = user;
      });
  }

  userHasEditPermissions(user: BoardUserData | undefined) {
    return user?.roles.admin ||
           user?.roles.board_manager;
  }

  userHasAdminPermissions(user: BoardUserData | undefined) {
    return user?.roles.admin;
  }
}
