import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public router: Router,
    public db: AngularFirestore,
    public auth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    var userSub = this.auth.user.subscribe(u => {
      userSub.unsubscribe();
      if (!u || !u.email) return;

      var userBoardsSub = this.db.collection('users')
            .doc(u.uid)
            .collection('boards')
            .valueChanges()
            .subscribe(b => {
              userBoardsSub.unsubscribe();
              if (b.length == 1) {
                this.router.navigate(['/boards', b[0]['board'] ]);
              }
              else {
                this.router.navigate(['/account/boards']);
              }
            });
    });
  }

}
