// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBgkTHA8IdLMyqGQ59JbhB0qT1aRKPGc4g",
    authDomain: "upboard-dev-5af83.firebaseapp.com",
    databaseURL: "https://upboard-dev-5af83-default-rtdb.firebaseio.com",
    //databaseURL: "http://localhost:9000/?ns=upboard-a073f",
    projectId: "upboard-dev-5af83",
    storageBucket: "upboard-dev-5af83.appspot.com",
    messagingSenderId: "874512022924",
    appId: "1:874512022924:web:d70eaa02853a5de7c4eaa9",
    measurementId: "G-6H2NF123XV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
