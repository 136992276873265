<app-nav-bar></app-nav-bar>
<ul class="nav nav-tabs">
  <li class="nav-item">
    <a [ngClass]="{
      'nav-link': true,
      'active': usersSelected
    }" [routerLink]="[ 'users' ]">Users</a>
  </li>
  <li class="nav-item">
    <a [ngClass]="{
      'nav-link': true,
      'active': styleSelected
    }" [routerLink]="[ 'style' ]">Style</a>
  </li>
  <li class="nav-item">
    <a [ngClass]="{
      'nav-link': true,
      'active': logoSelected
    }" [routerLink]="[ 'logo' ]">Logo</a>
  </li>
</ul>
<router-outlet (activate)='onActivate($event)' ></router-outlet>