import { Inject, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardUserData } from '../up-board-editor/up-board-editor.component';
import { DOCUMENT } from '@angular/common';
import * as icons from '@fortawesome/free-solid-svg-icons';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Board, Delivery, Employee } from '../up-board/up-board.component';
import * as moment from 'moment';
import { TimeUtils } from 'src/app/utils/time-utils';
import { getUsersNotInState } from 'src/app/utils/board-user-utils';
declare var $: any;

@Component({
  selector: 'app-up-board-viewer',
  templateUrl: './up-board-viewer.component.html',
  styleUrls: ['./up-board-viewer.component.css'],
})
export class UpBoardViewerComponent implements OnInit {
  icons = icons;
  boardId: string | null = null;
  boardUser: BoardUserData | undefined;
  loaded: boolean = false;
  board: Board | null | undefined;
  users: BoardUserData[] = [];
  active: Employee[] = [];
  up: Employee | undefined;
  onDeck: Employee | undefined;
  deliveries: Delivery[] = [];

  now: moment.Moment = moment();
  timeUtils: TimeUtils = new TimeUtils();
  timeout: NodeJS.Timeout | undefined;

  constructor(
    public db2: AngularFirestore,
    public auth: AngularFireAuth,
    private route: ActivatedRoute,
    public authService: AuthService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.updateNow();
  }

  toggleVisible = false;

  onMouseMove() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    $('#btnToggle').css('opacity', '100%');

    this.timeout = setTimeout(() => {
      $('#btnToggle').animate(
        { opacity: '0%' },
        {
          duration: 100,
        }
      );
    }, 1000);
  }

  toggleFullscreen() {
    // if already full screen; exit
    // else go fullscreen
    if (
      this.document.fullscreenElement ||
      this.document.webkitFullscreenElement ||
      this.document.mozFullScreenElement ||
      this.document.msFullscreenElement
    ) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    } else {
      var element = $('#view-container').get(0);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }

  updateNow() {
    this.now = moment();
    setTimeout(() => {
      this.updateNow();
    }, 1000);
  }

  available(employees: Employee[] | undefined): EmployeeWithIndex[] {
    return this.inState(employees, 'up');
  }

  withGuest(employees: Employee[] | undefined): EmployeeWithIndex[] {
    return this.inState(employees, 'with_guest');
  }

  withApt(employees: Employee[] | undefined): EmployeeWithIndex[] {
    return this.inState(employees, 'with_apt');
  }

  delivery(employees: Employee[] | undefined): EmployeeWithIndex[] {
    return this.inStates(employees, [
      'delivery',
      'with_guest_delivery',
      'with_apt_delivery',
    ]);
  }

  break(employees: Employee[] | undefined): EmployeeWithIndex[] {
    return this.inState(employees, 'break');
  }

  inState(
    employees: Employee[] | undefined,
    state: string
  ): EmployeeWithIndex[] {
    if (!employees) return [];

    const in_state: EmployeeWithIndex[] = [];

    var idx = 0;
    for (var i = 0; i < employees.length; i++) {
      var emp = employees[i];
      if (emp.state !== 'with_guest') {
        idx++;
      }
      if (emp.state === state) {
        in_state.push({
          emp: emp,
          index: idx,
        });
      }
    }

    return in_state;
  }

  inStates(
    employees: Employee[] | undefined,
    states: string[]
  ): EmployeeWithIndex[] {
    if (!employees) return [];

    const emps = this.unique(employees);

    const in_states: EmployeeWithIndex[] = [];

    var idx = 0;
    for (var i = 0; i < emps.length; i++) {
      var emp = emps[i];
      if (emp.state !== 'with_guest' && emp.state != 'with_guest_delivery') {
        idx++;
      }
      for (const state of states) {
        if (emp.state === state) {
          in_states.push({
            emp: emp,
            index: idx,
          });
        }
      }
    }

    return in_states;
  }

  inStore(deliveries: Delivery[] | undefined, arrived: boolean): Delivery[] {
    if (!deliveries) return [];

    const val: Delivery[] = [];

    for (const delivery of deliveries) {
      if (delivery.customer_in_store === arrived) {
        val.push(delivery);
      }
    }

    val.sort((a: Delivery, b: Delivery) => {
      if (a.customer_in_store != b.customer_in_store) {
        if (a.customer_in_store) return -1;
        return 1;
      }
      if (a.arrival_time > b.arrival_time) return 1;
      if (a.arrival_time < b.arrival_time) return -1;
      return 0;
    });

    return val;
  }

  onFullScreenChange(event: any) {
    if (document.fullscreenElement) {
      console.log('enter fullscreen');
      $('#btnToggle').css('visibility', 'hidden');
    } else {
      console.log('exit fullscreen');
      $('#btnToggle').css('visibility', 'visible');
    }
  }

  unique(emps: Employee[] | undefined) {
    const uniqueArray: Employee[] = [];

    emps?.forEach((a) => {
      const index = uniqueArray.findIndex((b) => {
        return b.email === a.email;
      });

      if (index === -1) {
        uniqueArray.push(a);
      }
    });

    return uniqueArray;
  }

  ngOnInit(): void {
    $('#view-container').on('fullscreenchange', this.onFullScreenChange);

    this.auth.user.subscribe((u) => {
      if (!u || !u.email) return;

      this.boardId = this.route.snapshot.paramMap.get('id');
      this.loaded = true;

      if (!this.boardId) return;

      this.db2
        .collection('boards')
        .doc(this.boardId)
        .collection('users')
        .doc<BoardUserData>(u.email)
        .valueChanges()
        .subscribe((user) => {
          this.boardUser = user;
        });

      this.db2
        .collection('boards')
        .doc(this.boardId)
        .collection<BoardUserData>('users')
        .valueChanges({ idField: 'email' })
        .subscribe((usrs) => {
          this.users = usrs;

          this.active = getUsersNotInState(this.users, 'inactive');

          let available = [];
          for (let i = 0; i < this.active.length; i++) {
            let emp = this.active[i];
            if (emp.state == 'up') {
              available.push(emp);
            }
          }

          this.up = available[0];
          this.onDeck = available[1];
        });

      this.db2
        .collection('boards')
        .doc<any>(this.boardId)
        .valueChanges()
        .subscribe((d) => {
          this.board = d.data;
          if (!this.board) return;

          this.board.viewerBackground =
            this.board.viewerBackground || 'assets/light-wood.jpg';
          $('#view-container').css(
            'background-image',
            `url(${this.board.viewerBackground})`
          );

          this.deliveries = [];
          if (this.board?.deliveries) {
            for (const delivery of this.board.deliveries) {
              this.deliveries.push({
                id: delivery.id,
                customer_in_store: delivery.customer_in_store,
                arrival_time: moment(delivery.arrival_time),
                stock_number: delivery.stock_number,
                vehicle: delivery.vehicle,
                emp: delivery.emp,
              });
            }
          }
        });
    });
  }
}

export class EmployeeWithIndex {
  emp: Employee;
  index: number;
  constructor(emp: Employee, index: number) {
    this.emp = emp;
    this.index = index;
  }
}
