import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardPermissionsService } from 'src/app/shared/services/board-permissions.service';
import { BoardUserData } from '../up-board-editor/up-board-editor.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  boardId: string | null = null;

  constructor(
    public authService: AuthService,
    public auth: AngularFireAuth,
    private route: ActivatedRoute,
    public boardPermissions: BoardPermissionsService,
  ) { }


  ngOnInit(): void {
    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;
      this.boardId = this.route.snapshot.paramMap.get('id');
      if (!this.boardId) return;
      this.boardPermissions.init(this.boardId, u.email);
    });
  }

}
