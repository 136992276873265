import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute } from '@angular/router';
import * as icons from '@fortawesome/free-solid-svg-icons';
import * as uuid from 'uuid';
declare var $: any;

@Component({
  selector: 'app-up-board-admin-style',
  templateUrl: './up-board-admin-style.component.html',
  styleUrls: ['./up-board-admin-style.component.css']
})
export class UpBoardAdminStyleComponent implements OnInit {

  public builtInBackgrounds = [
    "blue-sky.jpg",
    "cloudy-sky.jpg",
    "emerald-pool.jpg",
    "lake.jpg",
    "light-wood.jpg",
    "mountain-stream.jpg",
    "mountain.jpg",
    "rainbow-pool.jpg",
    "river-moose.jpg",
    "river-rocks.jpg",
    "rock.jpg",
    "smoky-ski-lift.jpg",
    "stream.jpg",
    "sunset-over-stream.jpg",
    "yellowstone-river.jpg"
  ];

  icons = icons;
  public selectedBackground: string = "assets/light-wood.jpg";

  public boardId: string | undefined;
  public uploadStatus: string = '';
  public customBackgrounds: { url: string, thumbnail?: string | undefined, path: string, thumbnailPath: string }[] = [];

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage
  ) { }

  fullImg(img: string) {
    return `assets/img/${img}`;
  }

  thumbnailImg(img: string) {
    return `assets/thumb/${img}`;
  }

  selectBackground(url: string) {
    if (!this.boardId) return;

    this.db.collection('boards')
            .doc(this.boardId)
            .set({
              data: {
                viewerBackground: url
              }
            }, { merge: true});
  }

  imgUrl(img: { url: string, thumbnail?: string | undefined, path: string, thumbnailPath: string }) {
    return img.thumbnail ? img.thumbnail : img.url;
  }

  deleteBackground(bg: {path: string, thumbnailPath: string}) {
    for (let i=0; i < this.customBackgrounds.length; i++) {
      if (this.customBackgrounds[i].path === bg.path) {
        this.customBackgrounds.splice(i, 1);
        break;
      }
    }
    const ref = this.storage.ref(bg.path);
    ref.delete();
    const thumbRef = this.storage.ref(bg.thumbnailPath);
    thumbRef.delete();
    this.db.collection('boards')
            .doc(this.boardId)
            .set({
              data: {
                customBackgrounds: this.customBackgrounds
              }
            }, { merge: true});
  }

  public selectedFile: any = null;
  public uploadPercent: string = '0%';

  async selectFile(event: any) {
    this.selectedFile = event.target.files[0];
  }

  async uploadFile() {
    if (!this.selectedFile) return;
    var fileName = uuid.v4();
    const filePath = `${this.boardId}/backgrounds/${fileName}.jpg`;
    const thumbPath = `${this.boardId}/backgrounds/thumb_${fileName}.jpg`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(this.selectedFile);
    task.percentageChanges().subscribe(p => {
      this.uploadPercent = Math.floor(p || 0) + '%';
    });
    this.uploadStatus = 'uploading';
    task.then(async (s) => {
      this.uploadStatus = 'finished';
      var url = await s.ref.getDownloadURL();

      if (this.boardId) {
        this.customBackgrounds.push({url, path: filePath, thumbnailPath: thumbPath});
        this.db.collection('boards')
                .doc(this.boardId)
                .set({
                  data: {
                    customBackgrounds: this.customBackgrounds
                  }
                }, { merge: true});
        $('#filePicker').val('');
        this.selectedFile = null;
      }

      setTimeout(() => {
        this.uploadStatus = '';
        this.uploadPercent = '0%';
      }, 2000);
    });
  }

  ngOnInit(): void {
    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;
      if (!this.route.parent) return;

      let boardId = this.route.parent.snapshot.paramMap.get('id');

      if (!boardId) return;
      this.boardId = boardId;

      this.db.collection('boards')
              .doc<any>(this.boardId)
              .valueChanges()
              .subscribe(b => {
                if (b.data) {
                  this.selectedBackground = b.data.viewerBackground;
                  this.customBackgrounds = b.data.customBackgrounds || [];

                  this.customBackgrounds.forEach(bg => {
                    if (!bg.thumbnailPath) return;
                    var t = this.storage.ref(bg.thumbnailPath).getDownloadURL().subscribe(url => {
                      bg.thumbnail = url;
                      t.unsubscribe();
                    });
                  });
                }
              });
    });

  }

}
