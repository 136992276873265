import { Component, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { BoardPermissionsService } from 'src/app/shared/services/board-permissions.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardUserData } from '../up-board-editor/up-board-editor.component';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Board, Delivery, Employee } from '../up-board/up-board.component';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { TimeUtils } from 'src/app/utils/time-utils';

declare var $: any;

@Component({
  selector: 'app-manage-deliveries',
  templateUrl: './manage-deliveries.component.html',
  styleUrls: ['./manage-deliveries.component.css'],
})
export class ManageDeliveriesComponent implements OnInit {
  icons = icons;
  boardId: string | null = null;
  boardUser: BoardUserData | undefined;
  loaded: boolean = false;
  board: Board | null | undefined;
  up: Employee | undefined;
  onDeck: Employee | undefined;
  deliveries: Delivery[] = [];
  salesmen: Employee[] = [];
  curDelivery: Delivery | undefined;

  now: moment.Moment = moment();
  modal_error: string | undefined;
  timeUtils: TimeUtils = new TimeUtils();

  constructor(
    public db2: AngularFirestore,
    public auth: AngularFireAuth,
    private route: ActivatedRoute,
    public authService: AuthService,
    public boardPermissions: BoardPermissionsService
  ) {
    this.updateNow();
  }

  updateNow() {
    this.now = moment();
    setTimeout(() => {
      this.updateNow();
    }, 1000);
  }

  removeDelivery(delivery: Delivery) {
    if (!this.board) return;
    if (!this.board.deliveries) return;

    this.deliveries = this.deliveries.filter((d) => d.id !== delivery.id);
    this.save();
  }

  editDelivery(delivery: Delivery) {
    if (!this.board) return;
    if (!this.board.deliveries) return;

    this.curDelivery = delivery;
    var val = this.curDelivery.arrival_time.format('YYYY-MM-DDTHH:mm:ss.SSS');

    $('#edit_arrival_time').val(val);
    $('#modal_edit_delivery').modal('show');
  }

  setArrivingNow(in_store: boolean) {
    if (!in_store) return;
    if (!this.curDelivery) return;

    var val = this.now.format('YYYY-MM-DDTHH:mm:ss.SSS');

    $('#edit_arrival_time').val(val);
  }

  saveDelivery(
    in_store: boolean,
    stock_number: string,
    arrival_time: moment.Moment,
    color: string,
    model: string,
    year: number,
    salesman: string
  ) {
    if (!this.curDelivery) return;

    this.curDelivery.customer_in_store = in_store;
    this.curDelivery.stock_number = stock_number;
    this.curDelivery.arrival_time = arrival_time;
    this.curDelivery.vehicle.color = color;
    this.curDelivery.vehicle.model = model;
    this.curDelivery.vehicle.year = year;
    this.curDelivery.emp = salesman;

    for (let i = 0; i < this.deliveries.length; i++) {
      if (this.deliveries[i].id == this.curDelivery.id) {
        this.deliveries[i] = this.curDelivery;
      }
    }
    $('#modal_edit_delivery').modal('hide');
    this.save();
  }

  addDelivery(delivery: Delivery) {
    if (!this.board) return;
    if (!this.board.deliveries) this.board.deliveries = [];
    delivery.id = uuidv4();

    if (delivery.customer_in_store) {
      delivery.arrival_time = moment();
    }

    this.deliveries.push(delivery);
    this.save();
    $('#modal_add_delivery').modal('hide');
  }

  sort(deliveries: Delivery[]): Delivery[] {
    deliveries.sort((a: Delivery, b: Delivery) => {
      if (a.customer_in_store != b.customer_in_store) {
        if (a.customer_in_store) return -1;
        return 1;
      }
      if (a.arrival_time > b.arrival_time) return 1;
      if (a.arrival_time < b.arrival_time) return -1;
      return 0;
    });

    return deliveries;
  }

  momentToDate(arrival_time: moment.Moment | undefined): Date {
    if (!arrival_time) return new Date();
    return arrival_time.toDate();
  }

  strToInt(str: string): number {
    return parseInt(str);
  }

  strToMoment(str: string): moment.Moment {
    return moment(str);
  }

  save(): void {
    if (!this.boardId) return;
    if (!this.board) return;

    this.board.deliveries = [];

    for (let i = 0; i < this.deliveries.length; i++) {
      const delivery = this.deliveries[i];

      this.board.deliveries.push({
        id: delivery.id,
        customer_in_store: delivery.customer_in_store,
        arrival_time: delivery.arrival_time.format('YYYY-MM-DD HH:mm:ss'),
        stock_number: delivery.stock_number || '',
        vehicle: delivery.vehicle,
        emp: delivery.emp,
      });
    }

    this.db2.collection('boards').doc(this.boardId).set(
      {
        data: this.board,
      },
      { merge: true }
    );
  }

  ngOnInit(): void {
    this.auth.user.subscribe((u) => {
      if (!u || !u.email) return;

      this.boardId = this.route.snapshot.paramMap.get('id');
      this.loaded = true;

      if (!this.boardId) return;

      this.db2
        .collection('boards')
        .doc<any>(this.boardId)
        .valueChanges()
        .subscribe((d) => {
          this.board = d.data;

          if (!this.board) return;
          if (!this.board.deliveries) this.board.deliveries = [];

          this.deliveries = [];
          for (let i = 0; i < this.board.deliveries.length; i++) {
            this.deliveries.push({
              id: this.board.deliveries[i].id,
              customer_in_store: this.board.deliveries[i].customer_in_store,
              arrival_time: moment(this.board.deliveries[i].arrival_time),
              vehicle: this.board.deliveries[i].vehicle,
              stock_number: this.board.deliveries[i].stock_number,
              emp: this.board.deliveries[i].emp,
            });
          }
        });

      this.db2
        .collection('boards')
        .doc(this.boardId)
        .collection<BoardUserData>('users')
        .valueChanges({ idField: 'email' })
        .subscribe((usrs) => {
          this.salesmen = usrs
            .filter((u) => u.roles.salesman)
            .map((u) => {
              return {
                email: u.email,
                name: u.name,
                order: u.order,
                phone: u.phone,
                photoUrl: u.photoUrl,
                state: u.state,
              } as Employee;
            });
        });
    });
  }
}
