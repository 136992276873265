<div [ngClass]="
    {
        'text-center': true,
        'up-board-employee': true,
        'up': up,
        'on-deck': onDeck
    }">
    <img [src]="emp?.photoUrl" class="rounded-circle float-left" style="width: 35px;">
    <span>
        <span *ngIf="index && emp?.state != 'with_guest_delivery'">[{{ index }}] </span>
        <b>{{ emp?.name }}</b>
    </span>
</div>
