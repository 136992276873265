import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminTokenComponent } from './components/admin-token/admin-token.component';
import { AuthComponent } from './components/auth/auth.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ManageDeliveriesComponent } from './components/manage-deliveries/manage-deliveries.component';
import { MyAccountBoardsComponent } from './components/my-account-boards/my-account-boards.component';
import { MyAccountSubscriptionComponent } from './components/my-account-subscription/my-account-subscription.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { UpBoardAdminLogoComponent } from './components/up-board-admin-logo/up-board-admin-logo.component';
import { UpBoardAdminStyleComponent } from './components/up-board-admin-style/up-board-admin-style.component';
import { UpBoardAdminUsersComponent } from './components/up-board-admin-users/up-board-admin-users.component';
import { UpBoardAdminComponent } from './components/up-board-admin/up-board-admin.component';
import { UpBoardManagerComponent } from './components/up-board-manager/up-board-manager.component';
import { UpBoardViewerComponent } from './components/up-board-viewer/up-board-viewer.component';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'signin', component: AuthComponent },
  { path: 'account', component: MyAccountComponent, canActivate: [AuthGuard], children: [
    { path: '', component: MyAccountBoardsComponent},
    { path: 'boards', component: MyAccountBoardsComponent},
    { path: 'subscription', component: MyAccountSubscriptionComponent},
  ] },
  { path: 'boards/:id', component: UpBoardManagerComponent, canActivate: [AuthGuard] },
  { path: 'boards/:id/view', component: UpBoardViewerComponent, canActivate: [AuthGuard] },
  { path: 'boards/:id/admin', component: UpBoardAdminComponent, canActivate: [AuthGuard], children: [
    { path: '', redirectTo: 'users', pathMatch: 'full' },
    { path: 'users', component: UpBoardAdminUsersComponent, canActivate: [AuthGuard] },
    { path: 'style', component: UpBoardAdminStyleComponent, canActivate: [AuthGuard] },
    { path: 'logo', component: UpBoardAdminLogoComponent, canActivate: [AuthGuard] },
  ] },
  { path: 'boards/:id/admin-token', component: AdminTokenComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactInfoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
