import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardPermissionsService } from 'src/app/shared/services/board-permissions.service';

@Component({
  selector: 'app-up-board-manager',
  templateUrl: './up-board-manager.component.html',
  styleUrls: ['./up-board-manager.component.css']
})
export class UpBoardManagerComponent implements OnInit {
  boardId: string | null = null;

  constructor(
    public auth: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    public boardPermissions: BoardPermissionsService,
    public router: Router,
  ) { }


  ngOnInit(): void {
    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;
      this.boardId = this.route.snapshot.paramMap.get('id');

      if (!this.boardId) return;
      this.boardPermissions.init(this.boardId, u.email);
      if (!this.boardPermissions.userHasEditPermissions(this.boardPermissions.boardUser)) {
        this.router.navigate([`/boards/${this.boardId}/view`]);
      }
    });
  }

}
