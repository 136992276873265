<nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
  <a class="navbar-brand" href="/">UpBoards</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" *ngIf="authService.isLoggedIn">
      <li>
        <a class="nav-link" [routerLink]=" [ '/account' ]">My Account</a>
      </li>
      <li *ngIf="boardId">
        <a class="nav-link" [routerLink]=" [ '/boards', boardId, 'view' ]">View Mode</a>
      </li>
      <li *ngIf="boardPermissions.userHasEditPermissions(boardPermissions.boardUser)">
        <a class="nav-link" [routerLink]=" [ '/boards', boardId ]">Manage Board</a>
      </li>
      <li *ngIf="boardPermissions.userHasEditPermissions(boardPermissions.boardUser)">
        <a class="nav-link" [routerLink]=" [ '/boards', boardId, 'admin' ]">Board Admin</a>
      </li>
      <li *ngIf="boardPermissions.userHasAdminPermissions(boardPermissions.boardUser)">
        <a class="nav-link" [routerLink]=" [ '/boards', boardId, 'admin-token' ]">Change Admin Token</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngIf="authService.isLoggedIn">
          <a [routerLink]="[ '/account' ]">
            <div *ngIf="authService.userData.photoURL">
              <img [src]="authService.userData.photoURL" class="rounded-circle" style="max-height: 30px; margin-right: 10px;" [title]="'Signed in as ' + authService.userData.email" />
            </div>
          </a>
        </li>
        <li>
          <a class="nav-link" [routerLink]=" [ '/contact' ]">Contact Info</a>
        </li>
        <li class="nav-item" *ngIf="authService.isLoggedIn">
          <a class="nav-link" style="cursor: pointer;" (click)="authService.SignOut()">Log Out</a>
        </li>
        <li *ngIf="!authService.isLoggedIn">
          <a class="nav-link" [routerLink]=" [ '/signin' ]">Sign In</a>
        </li>
      </ul>
    </form>
  </div>
</nav>