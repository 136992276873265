import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth';
import { BoardPermissionsService } from 'src/app/shared/services/board-permissions.service';

@Component({
  selector: 'app-up-board-editor',
  templateUrl: './up-board-editor.component.html',
  styleUrls: ['./up-board-editor.component.css']
})
export class UpBoardEditorComponent implements OnInit {
  boardId: string | null = null;

  constructor(
    public auth: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    public boardPermissions: BoardPermissionsService
  )
  { }

  ngOnInit(): void {

    this.auth.user.subscribe(u => {
      if (!u || !u.email) return;
      this.boardId = this.route.snapshot.paramMap.get('id');

      if (!this.boardId) return;
      this.boardPermissions.init(this.boardId, u.email);
    });
  }
}


export class BoardUser
{
  public email: string = ''
  public data: BoardUserData = {
    email: '',
    name: '',
    phone: '',
    photoUrl: '',
    state: '',
    order: 0,
    roles: {
      admin: false,
      board_manager: false,
      salesman: false
    }
  }
}

export class BoardUserData {
  public email: string = '';
  public name: string = '';
  public phone: string = '';
  public photoUrl: string = '';
  public state: string = '';
  public order: number = 0;
  public authToken?: string | undefined;
  public roles: BoardUserRoles = {
    admin: false,
    board_manager: false,
    salesman: false
  };
}

export class BoardUserRoles {
  public admin: boolean = false;
  public board_manager: boolean = false;
  public salesman: boolean = false;
}
