import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, QueryDocumentSnapshot, SnapshotOptions  } from '@angular/fire/compat/firestore';
import { BoardUserData } from 'src/app/components/up-board-editor/up-board-editor.component';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  constructor(
    public db: AngularFirestore
  )
  {
  }

  public async isLegalAuthToken(board: string, authToken: string): Promise<boolean> {
    var docs = await this.db.collection('boards')
           .doc(board)
           .collection<BoardUserData>('users', ref =>  {
             return ref.where('roles.admin', '==', true)
           })
           .ref.get()

    let matches = false;
    docs.forEach(d => {
      let d2 = d.data();
      console.log(d2.authToken);
      if (d.data().authToken === authToken) {
        console.log('MATCH!');
        matches = true;
      }
    });

    return matches;
  }
}

